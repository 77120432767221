<template>
    <div class="container">
        <div class="page-container">
            <div class="page-container__header">
                <a href="/" class="logo"><img src="/img/logo.svg"></a>
            </div>
        </div>

        <div class="section-header">
            <ButtonBack :name="'ВЕРНУТЬСЯ НАЗАД'" />

            <h1 class="title seo-title">
                Политика обработки и защиты персональных данных
            </h1></div>
        <div class="section-blog">
            <div class="section-blog__left typography">
                <p><strong>Полное наименование</strong></p>
                <p>Общество с ограниченной ответственностью «Межотраслевая Академия Инновационных Технологий»</p>
                <p><strong>Сокращенное наименование</strong></p>
                <p>ООО "МАИТ"</p>
                <p><strong>ИНН</strong> 3528329198</p>
                <p><strong>Юридический адрес:</strong></p>
                <p>162608, Вологодская область, г. о. город Череповец, г. Череповец, ул. Комарова, д. 10, помещ. 39</p>

                <p><strong>1. Общие положения</strong></p>
                <p><strong>1.1.</strong> Настоящая Политика в отношении обработки персональных данных (далее -<br>
                    Политика) составлена в соответствии с п. 2 ст. 18.1 Федерального закона Российской Федерации «О
                    персональных данных» № 152-ФЗ от 27 июля 2006 года (ред. от 21.07.2014) а также иных
                    нормативно-правовых актов Российской Федерации в области защиты и обработки персональных данных
                    и действует в отношении всех персональных данных (далее - Данных), которые Организация (далее по
                    тексту - Оператор, центр, общество) может получить от субъекта персональных данных, являющегося
                    стороной по договору оказания образовательных услуг (обучающий или его законный
                    представитель),гражданско-правовому договору, а так же от субъекта персональных данных,
                    состоящего с Оператором в отношениях, регулируемых трудовым законодательством (далее -
                    Работника).
                </p>
                <p><strong>1.2.</strong> Оператор обеспечивает защиту обрабатываемых персональных данных от<br>
                    несанкционированного доступа и разглашения, неправомерного использования или<br>
                    утраты в соответствии с требованиями Федерального закона от 27.07.2006 N 152-ФЗ “О персональных
                    данных”, Постановления Правительства Российской Федерации от 15.09. 2008 N 687 “Об утверждении
                    Положения об особенностях обработки персональных данных, осуществляемой без использования
                    средств автоматизации”, Постановлением Правительства РФ от 01.11.2012 N 1119 "Об утверждении
                    требований к защите персональных данных при их обработке в информационных системах персональных
                    данных", нормативных документов уполномоченных органов.
                </p>
                <p><strong>1.3.</strong> Изменение Политики</p>
                <p><strong>1.3.1.</strong> Оператор имеет право вносить изменения в настоящую Политику. При внесении изменений в
                    заголовке Политики указывается дата последнего обновления редакции.</p>
                <p>Новая редакция Политики вступает в силу с момента ее размещения на сайте, если иное не
                    предусмотрено новой редакцией Политики.</p>
                <p><strong>1.3.2.</strong> Действующая редакция хранится в электронном виде на официальном сайте Оператора по адресу:
                    <a href="https://mait-nauka.ru/">https://mait-nauka.ru/</a> и <a href="https://mait-lk.ru/">https://mait-lk.ru/</a>
                </p>
                <p><strong>2. Термины и принятые сокращения</strong></p>
                <p><strong>Персональные данные (ПД) </strong>- любая информация, относящаяся к прямо или косвенно
                    определенному или определяемому физическому лицу (субъекту персональных данных);</p>
                <p><strong>Обработка персональных данных </strong>- любое действие (операция) или совокупность<br>
                    действий (операций), совершаемых с использованием средств автоматизации или без использования
                    таких средств с персональными данными, включая сбор, запись,<br>
                    систематизацию, накопление, хранение, уточнение (обновление, изменение),<br>
                    извлечение, использование, передачу (распространение, предоставление, доступ),<br>
                    обезличивание, блокирование, удаление, уничтожение персональных данных;
                </p>
                <p><strong>Автоматизированная обработка персональных данных </strong>- обработка персональных данных
                    с помощью средств вычислительной техники;</p>
                <p><strong>Информационная система персональных данных (ИСПД) </strong>- совокупность<br>
                    содержащихся в базах данных персональных данных и обеспечивающих их обработку информационных
                    технологий и технических средств;
                </p>
                <p><strong>Персональные данные, сделанные общедоступными субъектом персональных<br>
                    данных </strong>- ПД, доступ неограниченного круга лиц к которым предоставлен субъектом
                    персональных данных либо по его просьбе;
                </p>
                <p><strong>Блокирование персональных данных </strong>- временное прекращение обработки<br>
                    персональных данных (за исключением случаев, если обработка необходима для<br>
                    уточнения персональных данных);
                </p>
                <p><strong>Уничтожение персональных данных </strong>- действия, в результате которых становится<br>
                    невозможным восстановить содержание персональных данных в информационной<br>
                    системе персональных данных и (или) в результате которых уничтожаются<br>
                    материальные носители персональных данных;
                </p>
                <p><strong>Оператор - </strong>организация, обрабатывающая персональные данные</p>
                <p><strong>3.Обработка персональных данных</strong></p>
                <p><strong>1.1. Получение ПД.</strong></p>
                <p><strong>1.1.1.</strong> Все ПД следует получать от самого субъекта. Если ПД субъекта можно получить только у
                    третьей стороны, то Субъект должен быть уведомлен об этом или от него должно быть получено
                    согласие.</p>
                <p><strong>1.1.2.</strong> Оператор должен сообщить Субъекту о целях, предполагаемых источниках и способах получения
                    ПД, характере подлежащих получению ПД, перечне действий с ПД, сроке, в течение которого
                    действует согласие и порядке его отзыва, а также о последствиях отказа Субъекта дать письменное
                    согласие на их получение.</p>
                <p><strong>1.1.3.</strong> Документы, содержащие ПД создаются путем:</p>
                <p>• копирования оригиналов документов (паспорт, документ об образовании,<br>
                    свидетельство ИНН, пенсионное свидетельство и др.);
                </p>
                <p>• внесения сведений в учетные формы;</p>
                <p>• получения оригиналов необходимых документов (трудовая книжка, медицинское заключение,
                    характеристика и др.).</p>
                <p><strong>1.2. Обработка ПД</strong></p>
                <p><strong>1.2.1.</strong> Обработка персональных данных осуществляется:</p>
                <p>• С согласия субъекта персональных данных на обработку его персональных данных;</p>
                <p>• В случаях, когда обработка персональных данных необходима для осуществления и выполнения
                    возложенных законодательством Российской Федерации функций, полномочий и обязанностей;</p>
                <p>• В случаях, когда осуществляется обработка персональных данных, доступ<br>
                    неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его
                    просьбе (далее - персональные данные, сделанные общедоступными субъектом персональных данных).
                </p>
                <p><strong>1.2.2.</strong> Цели обработки персональных:</p>
                <p>• Обеспечение организацией оказания медицинской помощи населению, а также<br>
                    наиболее полного исполнения обязательств и компетенций в соответствии с
                </p>
                <p>• Федеральными законами «Об основах охраны здоровья граждан в Российской<br>
                    Федерации» от 21.11.2011 № 323-ФЗ; «Об обязательном медицинском страховании<br>
                    граждан в Российской Федерации» от 29 ноября 2010 года № 326-ФЗ; «Правила<br>
                    предоставления медицинскими организациями платных медицинских услуг»<br>
                    утвержденные Постановлением Правительства Российской Федерации от 04.10.2012 N 1006;
                </p>
                <p>• Осуществление трудовых отношений;</p>
                <p>• Осуществление гражданско-правовых отношений.</p>
                <p><strong>1.2.3.</strong> Категории субъектов персональных данных.</p>
                <p>В центре обрабатываются ПД следующих субъектов ПД :</p>
                <p>• физические лица, состоящие с обществом в трудовых отношениях;</p>
                <p>• физические лица, являющие близкими родственниками сотрудников общества;</p>
                <p>• физические лица, уволившиеся из общества;</p>
                <p>• физические лица, являющиеся кандидатами на работу;</p>
                <p>• физические лица, состоящие с обществом в гражданско-правовых отношениях</p>
                <p>• физические лица, обратившиеся в общество за медицинской помощью.</p>
                <p><strong>1.2.4.</strong> ПД, обрабатываемые Оператором:</p>
                <p>• Данные полученные при осуществлении трудовых отношений;</p>
                <p>• Данные полученные для осуществления отбора кандидатов на работу;</p>
                <p>• Данные полученные при осуществлении гражданско-правовых отношений.</p>
                <p>• Данные полученные при оказании медицинской помощи</p>
                <p><strong>1.2.5.</strong> Обработка персональных данных ведется:</p>
                <p>• С использованием средств автоматизации</p>
                <p>• Без использования средств автоматизации</p>
                <p><strong>1.3. Хранение ПД</strong></p>
                <p><strong>1.3.1.</strong> ПД Субъектов могут быть получены, проходить дальнейшую обработку и<br>
                    передаваться на хранение как на бумажных носителях, так и в электронном виде.
                </p>
                <p><strong>1.3.2.</strong> ПД, зафиксированные на бумажных носителях, хранятся в запираемых шкафах, либо в запираемых
                    помещениях с ограниченным правом доступа (регистратура).</p>
                <p><strong>1.3.3.</strong> ПД Субъектов, обрабатываемые с использованием средств автоматизации в<br>
                    разных целях, хранятся в разных папках (вкладках).
                </p>
                <p><strong>1.3.4.</strong> Не допускается хранение и размещение документов, содержащих ПД, в открытых электронных
                    каталогах (файлообменниках) в ИСПД.</p>
                <p><strong>1.3.5.</strong> Хранение ПД в форме, позволяющей определить субъекта ПД, осуществляется не дольше, чем
                    этого требуют цели их обработки, и они подлежат уничтожению по достижении целей обработки или в
                    случае утраты необходимости в их достижении.</p>
                <p><strong>1.4. Уничтожение ПД</strong></p>
                <p><strong>1.4.1.</strong> Уничтожение документов (носителей), содержащих ПД производится путем<br>
                    сожжения, дробления (измельчения), химического разложения, превращения в<br>
                    бесформенную массу или порошок. Для уничтожения бумажных документов<br>
                    допускается применение шредера.
                </p>
                <p><strong>1.4.2.</strong> ПД на электронных носителях уничтожаются путем стирания или форматирования носителя.</p>
                <p><strong>1.4.3.</strong> Уничтожение производится комиссией. Факт уничтожения ПД подтверждается<br>
                    документально актом об уничтожении носителей, подписанным членами комиссии.
                </p>
                <p><strong>1.5. Передача ПД</strong></p>
                <p><strong>1.5.1.</strong> Оператор передает ПД третьим лицам в следующих случаях:</p>
                <p>• Субъект выразил свое согласие на такие действия;</p>
                <p>• Передача предусмотрена российским или иным применимым законодательством в рамках установленной
                    законодательством процедуры.</p>
                <p><strong>1.5.2.</strong> Перечень лиц, которым передаются ПД.</p>
                <p>Третьи лица, которым передаются ПД:</p>
                <p>• Пенсионный фонд РФ для учета (на законных основаниях);</p>
                <p>• Налоговые органы РФ (на законных основаниях);</p>
                <p>• Фонд социального страхования (на законных основаниях);</p>
                <p>• Территориальный фонд обязательного медицинского страхования (на законных основаниях);</p>
                <p>• Страховые медицинские организации по обязательному и добровольному медицинскому страхованию (на
                    законных основаниях);</p>
                <p>• Банки для начисления заработной платы (на основании договора);</p>
                <p>• Органы МВД в случаях, установленных законодательством.</p>
                <p><strong>4. Защита персональных данных</strong></p>
                <p><strong>4.1.</strong> В соответствии с требованиями нормативных документов Оператором создана<br>
                    система защиты персональных данных (СЗПД), состоящая из подсистем правовой,<br>
                    организационной и технической защиты.
                </p>
                <p><strong>4.2.</strong> Подсистема правовой защиты представляет собой комплекс правовых,<br>
                    организационно-распорядительных и нормативных документов, обеспечивающих<br>
                    создание, функционирование и совершенствование СЗПД.
                </p>
                <p><strong>4.3.</strong> Подсистема организационной защиты включает в себя организацию структуры управления СЗПД,
                    разрешительной системы, защиты информации при работе с сотрудниками, партнерами и сторонними
                    лицами.</p>
                <p><strong>4.4.</strong> Подсистема технической защиты включает в себя комплекс технических,<br>
                    программных, программно-аппаратных средств, обеспечивающих защиту ПД.
                </p>
                <p><strong>4.5.</strong> Основными мерами защиты ПД, используемыми Оператором, являются:</p>
                <p><strong>4.5.1.</strong> Назначение лица ответственного за обработку ПД, которое осуществляет<br>
                    организацию обработки ПД, обучение и инструктаж, внутренний контроль за<br>
                    соблюдением учреждением и его работниками требований к защите ПД;
                </p>
                <p><strong>4.5.2.</strong> Определение актуальных угроз безопасности ПД при их обработке в ИСПД, и разработка мер и
                    мероприятий по защите ПД;</p>
                <p><strong>4.5.3.</strong> Разработка политики в отношении обработки персональных данных;</p>
                <p><strong>4.5.4.</strong> Установление правил доступа к ПД, обрабатываемым в ИСПД, а также<br>
                    обеспечения регистрации и учета всех действий, совершаемых с ПД в ИСПД;
                </p>
                <p><strong>4.5.5.</strong> Установление индивидуальных паролей доступа сотрудников в информационную систему в
                    соответствии с их производственными обязанностями;</p>
                <p><strong>4.5.6.</strong> Применение прошедших в установленном порядке процедуру оценки соответствия средств защиты
                    информации;</p>
                <p><strong>4.5.7.</strong> Сертифицированное антивирусное программное обеспечение с регулярно<br>
                    обновляемыми базами;
                </p>
                <p><strong>4.5.8.</strong> Сертифицированное программное средство защиты информации от<br>
                    несанкционированного доступа;
                </p>
                <p><strong>4.5.9.</strong> Сертифицированные межсетевой экран и средство обнаружения вторжения;</p>
                <p><strong>4.5.10.</strong> Соблюдаются условия, обеспечивающие сохранность ПД и исключающие<br>
                    несанкционированный к ним доступ;
                </p>
                <p><strong>4.5.11.</strong> Обнаружение фактов несанкционированного доступа к персональным данным и принятия мер;</p>
                <p><strong>4.5.12.</strong> Восстановление ПД, модифицированных или уничтоженных вследствие<br>
                    несанкционированного доступа к ним;
                </p>
                <p><strong>4.5.13.</strong> Обучение работников Оператора непосредственно осуществляющих обработку персональных
                    данных, положениям законодательства Российской Федерации о персональных данных, в том числе
                    требованиям к защите персональных данных, документами, определяющими политику Оператора в
                    отношении обработки персональных данных, локальным актам по вопросам обработки персональных
                    данных;</p>
                <p><strong>4.5.14.</strong> Осуществление внутреннего контроля и аудита.</p>
                <p><strong>5. Основные права субъекта ПД и обязанности оператора</strong></p>
                <p><strong>5.1.</strong> Основные права субъекта ПД</p>
                <p>Субъект имеет право на доступ к его персональным данным и следующим сведениям:</p>
                <p>- подтверждение факта обработки ПД оператором;</p>
                <p>- правовые основания и цели обработки ПД;</p>
                <p>- цели и применяемые оператором способы обработки ПД;</p>
                <p>- наименование и место нахождения оператора, сведения о лицах (за исключением<br>
                    работников оператора), которые имеют доступ к ПД или которым могут быть раскрыты ПД на основании
                    договора с оператором или на основании федерального закона;
                </p>
                <p>- сроки обработки персональных данных, в том числе сроки их хранения;</p>
                <p>- порядок осуществления субъектом ПД прав, предусмотренных настоящим<br>
                    Федеральным законом;
                </p>
                <p>- наименование или фамилию, имя, отчество и адрес лица, осуществляющего обработку ПД по поручению
                    оператора, если обработка поручена или будет поручена такому лицу;</p>
                <p>- обращения к оператору и направлению ему запросов;</p>
                <p>- обжалование действий или бездействия оператора.</p>
                <p><strong>5.2.</strong> Обязанности Оператора</p>
                <p>Оператор обязан:</p>
                <p>- при сборе ПД предоставить информацию об обработке ПД;</p>
                <p>- в случаях, если ПД были получены не от субъекта ПД, уведомить субъекта;</p>
                <p>- при отказе в предоставлении ПД субъекту разъясняются последствия такого отказа;</p>
                <p>- опубликовать или иным образом обеспечить неограниченный доступ к документу, определяющему его
                    политику в отношении обработки ПД, к сведениям о реализуемых требованиях к защите ПД;</p>
                <p>- принимать необходимые правовые, организационные и технические меры или<br>
                    обеспечивать их принятие для защиты ПД от неправомерного или случайного доступа к ним,
                    уничтожения, изменения, блокирования, копирования, предоставления, распространения ПД а также от
                    иных неправомерных действий в отношении ПД;
                </p>
                <p>давать ответы на запросы и обращения Субъектов ПД, их представителей и<br>
                    уполномоченного органа по защите прав субъектов ПД.
                </p>
            </div>
        </div>

        <div class="container footer-login">
            <div class="footer-login__wrap">
                <a href="/" class="logo">
                    <img src="/img/logo.svg">
                </a>
                <div class="footer-login__text">Принимаем к оплате:</div>
                <img class="pay-info" src="/img/pay-keeper.png"/>
            </div>
            <div class="footer-login__grid">
                <a href="/basic-information" class="footer-login__link">Основные сведения</a>
                <a href="/information" class="footer-login__link">
                    Информация об оплате, возврате оплаты, доставке и подачи заявления на обучение
                </a>
                <a href="/personal-data-policy/personal-data-policy" class="footer-login__link">
                    Политика обработки персональных данных
                </a>
            </div>
        </div>
    </div>
</template>

<script>

import { defineComponent } from 'vue'
import ButtonBack from '@/components/button-back.vue'

export default defineComponent({
    components: { ButtonBack }
})
</script>
